<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="signup">
        <h1 class="lg:text-2xl text-xl font-semibold mb-6">Đăng ký</h1>
        <span>Bạn đã có tài khoản?
           <router-link :to="{name: 'Login'}" class="text-blue-800 ms-1 ml-1">Đăng nhập</router-link>
        </span>
        <div class="bg-red-500 border p-4 relative rounded-md uk-alert" v-if="message">
          <p class="text-white">{{ message }}</p>
        </div>
        <div>
          <label class="mb-0" for="username">Tên đăng nhập</label>
          <input required v-model="username" id="username" type="text" placeholder="Username của bạn"
                 class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <label class="mb-0" for="email">Email</label>
          <input required v-model="email" id="email" type="text" placeholder="Email của bạn"
                 class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <label class="mb-0" for="password">Mật khẩu</label>
          <input v-on:blur="passwordType = 'password'" v-on:focus="passwordType = 'text'" required v-model="password"
                 id="password" :type="passwordType" placeholder="" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
        </div>
        <div>
          <div>
            <div class="checkbox">
              <input type="checkbox" id="chekcbox2" v-model="agreed">
              <label for="chekcbox2">
                <span class="checkbox-icon"></span> <span class="font-medium">Tôi đồng ý với
          <router-link target="_blank" :to="{name: 'PageDetail', params: {slug: 'quy-dinh'}}"
                       class="text-blue-800 ms-1">
            Quy định
          </router-link> của trang nhạc</span>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button type="submit" class="bg-blue-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
            Đăng ký
          </button>
        </div>
        <div class="w-full flex justify-content-center">
          <GoogleLogin :callback="callback"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {changePageTitle} from "../core/services/utils.service";

export default {
  name: 'Register',
  data() {
    return {
      username: "",
      email: "",
      password: "",
      disabled: false,
      message: "",
      passwordType: "text",
      agreed: false
    }
  },
  methods: {
    callback(response) {
      window.postMessage({action: "onSignIn", value: {type: "google", token: response.credential}}, window.location.origin);
    },
    signup() {
      if (!this.agreed) {
        this.message = "Bạn chưa đồng ý với quy định của trang nhạc";
        return;
      }

      this.disabled = true;
      this.message = "";
      let query = `mutation($username: String!, $email: String!, $password: String!) {
        signup(username: $username, email: $email, password: $password) {
          id
        }
      }`;
      ApiService.graphql(query, {username: this.username, email: this.email, password: this.password})
          .then(({data}) => {
            if (data.data && data.data.signup) {
              this.disabled = false;
              this.$router.push({name: "Login", query: {ref: 'register'}});
            } else {
              this.disabled = false;
              this.message = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.message = response.message;
          });
    }
  },
  mounted() {
    changePageTitle("Đăng ký")
  }
}
</script>
